import MarkDownIt from 'markdown-it';

const md = new MarkDownIt({ breaks: true });

export function inlineMarkdownToHtml(markdown?: string) {
  if (!markdown) {
    console.warn('Provided markdown should not be empty. Providing empty string as fallback.');

    return '';
  }

  return md.renderInline(markdown);
}
