import AuthProvider from '../../traits/auth/provider';
import { ShortLinkRedirect } from './ShortLinkRedirect';

export const ShortLinkRedirectPage = () => {
  return (
    <AuthProvider>
      <ShortLinkRedirect />
    </AuthProvider>
  );
};
