import { createBrowserRouter } from 'react-router-dom';
import { Layout } from '../layout/layout';
import { Home } from '../../features/volunteer/home/Home';
import { NotFoundPage } from '../../features/notFound/NotFoundPage';
import { ThankYouPage } from '../../features/volunteer/thankYou/ThankYouPage';
import { DashboardPage } from '../../features/volunteer/dashboard/DashboardPage';
import { PublicLayout } from '../layout/publicLayout';
import { PlanPaymentRedirect } from '../../features/volunteer/paymentRedirect/PlanPaymentRedirect';
import { SignAgreementPage } from '../../features/volunteer/signAgreement/SignAgreementPage';
import * as Sentry from '@sentry/react';
import { PaymentCheckoutQrCode } from '../../features/volunteer/paymentCheckoutQrCode/planFlow/PaymentCheckoutQrCode';
import { VolunteerRating } from '../../features/volunteer/volunteerRating/VolunteerRating';
import { ShortLinkRedirectPage } from '../../features/shortLinkRedirect/ShortLinkRedirectPage';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '/404',
        element: <NotFoundPage />,
      },
      {
        path: '/short/:shortLinkId',
        Component: ShortLinkRedirectPage,
      },
    ],
  },
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        path: '/:slug/sign-agreement/:saleId',
        Component: SignAgreementPage,
      },
      {
        path: '/:slug/sign-confirmation',
        element: <ThankYouPage />,
      },
      {
        path: '/:slug/payment-redirect/:saleId',
        element: <PlanPaymentRedirect />,
      },
      {
        path: '/:slug/payment-qr-code/:saleId',
        element: <PaymentCheckoutQrCode />,
      },
      {
        path: '/:slug/rating/:ratingId',
        element: <VolunteerRating />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/:slug',
        element: <Home />,
      },
      {
        path: '/:slug/dashboard',
        element: <DashboardPage />,
      },
    ],
  },
]);
