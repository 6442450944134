import { useApi } from '../../traits/hooks/useApi';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';

export const ShortLinkRedirect = () => {
  const api = useApi();
  const { shortLinkId } = useParams();

  useQuery({
    queryFn: () => {
      if (!shortLinkId) {
        console.error(
          '"shortLinkId" is undefined.\n' + 'If this link was autogenerated there might be issues with the generation.',
        );
      }

      return api.shortLinksControllerFindOne(shortLinkId ?? '');
    },
    onSuccess: data => {
      return (window.location.href = data.data.url);
    },
    queryKey: ['shortLink', shortLinkId],
  });

  return (
    <MainContentWrapper>
      <Flex height="100%" width="100%" justify="center" align="center">
        <Margin $top="7xl" />
        <LoadingCircle />
      </Flex>
    </MainContentWrapper>
  );
};
