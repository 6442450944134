import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import { Margin } from '../layout';
import React from 'react';
import { ErrorText } from '../typography';
import MDEditor, { commands } from '@uiw/react-md-editor';

type CharityStyles = { fontColorHex?: string; bgColorHex?: string };

const StyledMDTextArea = styled(MDEditor)<{ charityStyles?: CharityStyles }>`
  border: 1px solid ${defaultTheme.colors.grey};
  border-radius: 4px;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  font-family: 'Inter';
  font-size: 0.8rem;
  resize: vertical;

  &:focus-within {
    border: 1px solid ${({ charityStyles }) => charityStyles?.bgColorHex ?? defaultTheme.colors.mainRed};
    outline: none;
  }

  &::placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }

  &:-ms-input-placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }

  .wmde-markdown {
    font-size: inherit !important;
  }
`;

const Label = styled.p`
  font-weight: 500;
`;

type Props = {
  label?: string;
  charityStyles?: CharityStyles;
  onChangeText?: (text: string) => void;
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
};

export const MDTextArea = (props: Props) => {
  return (
    <div>
      {props.label && (
        <>
          <Label>{props.label}</Label>
          <Margin $top="s" />
        </>
      )}

      <StyledMDTextArea
        commands={[commands.bold, commands.italic]}
        data-color-mode="light"
        style={{ whiteSpace: 'pre-wrap' }}
        {...props}
        charityStyles={props.charityStyles}
        onChange={(_?: string, event?: React.ChangeEvent<HTMLTextAreaElement>) => {
          if (event) {
            props?.onChange?.(event);
            props?.onChangeText?.(event.target.value);
          } else {
            console.warn('No event found onChange for MDTextArea. This is not supposed to happen.');
          }
        }}
      />
      <Margin $top="s" />
      {props.errorMessage && <ErrorText>{props.errorMessage}</ErrorText>}
    </div>
  );
};
